

@import url('https://fonts.googleapis.com/css2?family=Andika:wght@400;700&family=Lato:wght@300&family=Manrope:wght@200;700&family=Montserrat:wght@300;400;700&family=Poppins:wght@300;400;500;600&family=Quicksand:wght@300;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Andika:wght@400;700&family=Lato:wght@300&family=Manrope:wght@200;300;400;500;600;800&family=Montserrat:wght@300;400;700&family=Poppins:wght@300;400;500;600&family=Quicksand:wght@300;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');


* body {
    font-family: "Plus Jakarta Sans", sans-serif !important;
    
}